import { Box } from "@mui/material"

import { GuideList } from "./GuideList"

import { GoBack } from "../../../molecules/GoBack"

import { GUIDELINES } from "./utils/constants"


export const Guidelines = ({
    nextStep  
}) => {
    return(
        <>
            <GoBack />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
                backgroundColor: "white"
            }}>
                <GuideList
                    guidelines={GUIDELINES}
                    nextStep={nextStep}
                />
            </Box>
        </>
    )
}