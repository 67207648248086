import { Box, Typography, Button } from "@mui/material"
import { ArrowForward } from '@mui/icons-material';
import { GUIDELINES } from "./utils/constants";

import { useMediaQuery } from "react-responsive";

import { useTranslation } from 'react-i18next';

export const GuideList = ({
    nextStep
}) => {
    const { t } = useTranslation('components/all');
    
    const isSmallMobile = useMediaQuery({ maxWidth: 390 });
    console.log('isSmallMobile', isSmallMobile);
    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: "75vh",
                justifyContent: "center"
            }}>
                {Object.keys(GUIDELINES).map((key, idx) => {
                    return (
                        <Box 
                            key={idx}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: "row",
                                height: "25%"
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: '50%',
                                marginLeft: '10px',
                                maxWidth: "100px"
                            }}>
                                <img
                                    src={GUIDELINES[key].icon}
                                    style={{
                                        height: "45px"
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "85%",
                                alignItems: "center",
                                height: "100px"
                            }}>
                                <Typography
                                    color="black"
                                    fontWeight="bold"
                                    fontSize="20px"
                                    width='90%'
                                    textAlign="start"
                                    fontFamily={"Helvetica Neue"}
                                >
                                    {t(GUIDELINES[key].body)}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Button
                onClick={nextStep}
                variant="contained"
                sx={{
                    display: "flex",
                    textTransform: "none",
                    height: "65px",
                    width: "65px",
                    backgroundColor: "black",
                    border: "1px black solid",
                    borderRadius: "50px"
                }}
            >
                <ArrowForward 
                    sx={{
                        width: "30px",
                        height: "30px"
                    }}
                />
            </Button>
        </>
    )
}