import { useEffect, useRef } from "react"

import { Box } from "@mui/material"

import { Animation } from "./Animation"

import { GoBack } from "../../../../molecules/GoBack"

const STEPS = {
    ANIMATION: "Animation",
    CALIBRATE: "Calibrate"
}

export const Inclination = ({
    startTutorial,
    createDetector,
    stepNumber,
    previousScreen
}) => {
    const detectorCreated = useRef(false)

    const nextStep = () => {
        console.log('Go hit');
        if (stepNumber === 0) startTutorial();
    }

    useEffect(() => {
        if (!detectorCreated.current) {
            createDetector()
            detectorCreated.current = true
        }
    }, [])

    return(
        <>
            <Box sx={{
                position: "absolute",
                zIndex: 9999999,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100vw",
                height: "100vh",
            }}>
                <Animation nextStep={nextStep} />
            </Box>
            <GoBack onClick={previousScreen}/>
        </>
    )
}